@import "common/all";

.translation_missing {
  border: 1px dotted red;
}

/* headers */
h1,
h2 {
  font-size: 14px;
  padding-bottom: 10px;
}
h1 small.key {
  font-size: 12px;
  font-weight: normal;
}


/* tab bar */
.tabBar {
  @include box-shadow(inset 0 -1px 0 #bdb1a5);
  height: 38px;
  margin-bottom: 30px;
}
.tabBar .tab {
  @include gradient (#f7f8ea, #ece5d2);
  border-width: 1px 1px 1px 0;
  border-style: solid;
  border-color: #bdb1a5;
  height: 38px;
  line-height: 38px;
  float: left;
  padding: 0 18px;
  @include box-shadow(0 1px 0 #ffffff inset);
  text-decoration: none;
  color: black;
}
.tabBar .tab:hover {
  background: #ece5d2;
}
.tabBar .tab:first-of-type {
  border-left: 1px solid #bdb1a5;
}
.tabBar .tab.active,
.tabBar .tab.active:hover {
  background: white;
  border-bottom-color: white;
}
.tabBar .tab span {
  background-color: #d2c8bc;
  font-size: 10px;
  display: inline-block;
  height: 13px;
  line-height: 12px;
  text-shadow: 0 1px 0 white;
  padding: 0 3px;
  position: relative;
  top: -1px;
  @include border-radius(3px);
}
.tabBar .tab.active span {
  background-color: #e3dfdc;
}
.tabBar .btn {
  float: right;
  margin-left: 10px;
}

.tabBar form {
  float: right;
  margin-left: 10px;
  input[type="text"], input[type="button"] {
    height: 28px;
    display: inline-block;
  }
}

.loginForm {
  width: 554px;
  margin: 0 auto;
}
h2.loginForm {
  padding-top: 20px;
}
.loginForm.invitationBox {
  width: 570px;
}

.invitationBox #error_explanation h2 {
  font-size: 1em;
}

ul {
  padding-left: 20px;
  list-style-type: square;
}
ol {
  padding-left: 20px;
}
div.container ul,
ol {
  padding-bottom: 20px;
}
ol ol,
ol ul,
ul ul,
ul ol {
  padding-bottom: 0;
}
ul li,
ol li {
  font-size: 12px;
  line-height: 18px;
}
a {
  color: $smudgeblue;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

dl {
  overflow: auto;
  padding: 0 0 20px;
}
dl dt {
  float: left;
  clear: left;
  width: 100px;
  padding: 5px;
  line-height: 18px;
  font-weight: bold;
  text-align: right;
}
dl dd {
  margin-left: 100px;
  padding: 5px;
  line-height: 18px;
}
dl dd p {
  margin: 0;
  line-height: 18px;
}

dl.links {
  dt {
    text-align: left;
    width: 200px;
    &.internal:after {
      content: '*'
    }
  }
  dd {
    margin-left: 150px;
  }
}

#team-contacts th {
  text-align: left;
  border-bottom: #BAB1A8 1px solid;
  padding: 15px;
}

h1#search-hits {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: #BAB1A8 1px solid;
}

p.hint {
  color: #BAB1A8;
}
