/* Based on https://www.brandingnet.ch/styleguide-v4-3.pdf */

/* base colors */
$yellow:        #fc0;
$lightyellow:   #ffe580;

$red:           #f00;
$anthrazit:     #333;
$darkgrey:      #666;
$grey:          #999;
$lightgrey:     #ccc;

/* gestaltungsfarben */
$smudgeyellow:  #DFC300;
$baseyellow:    #ECD46F;
$paleyellow:    #F3E5A8;

$smudgeorange:  #DB8C16;
$baseorange:    #E8B365;
$paleorange:    #F1D0A3;

$smudgered:     #C60B2B;
$basered:       #DB7659;
$palered:       #ffebde;

$smudgeviolett: #80007B;
$baseviolett:   #A968A3;
$paleviolett:   #CBA3C6;

$smudgeblue:    #235AA6;
$baseblue:      #5C89C7;
$paleblue:      #A2B2D6;

$smudgegreen:   #279E34;
$basegreen:     #88BA78;
$palegreen:     #BAD6B0;

/* custom colors */
$beige:         #BDB1A5;
$darkbeige:     #806E60;