
@import "common/all";

p.empty, p.empty strong {
  font-size: 16px;
  margin-bottom: 40px;
}

.alert {
  border: 2px solid $beige;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.alerts .alert {
  border-width: 2px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 40px;
  }
}

.stamp {
  color: $darkbeige;
  font-size: 11px;
  line-height: 21px;
  a {
    color: #7c7268;
    font-size: 11px;
    &:hover {
      text-decoration: underline;
      color: #000;
    }
  }
  span.author:after {
    content: ', ';
  }
  time {
    font-size: 11px;
    &.numeric {
      // hide shorter timestamp, only visible on mobile devices
      display: none;
    }
  }
}

/* alert headers */
.alert .header {
  background-color: #faf6ea;
  position: relative;
  padding: 20px 140px 10px 20px;
  overflow: auto;
  border-bottom: 1px solid #bab2a5;

  /* left side */
  h3,
  h3 a {
    font-size: 21px;
    line-height: 21px;
    text-decoration: none;
    color: #000;
  }
  h3,
  ul.tags {
    float: left;
    padding: 5px 10px 0 0;
  }

  /* right side */
  .right {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 100px;
    text-align: right;

    .number {
      padding: 2px 0 0;
      display: block;
      font-size: 15px;
      color: $darkbeige;
      line-height: 20px;
    }
  }
}

/* hide edit button in list */
.alerts .alert .header .btnEdit {
  display: none;
}

/* alert content */
.alert .body {
  padding: 20px 20px 0;
  clear: both;
  position: relative;
  overflow: visible;

  .arrow {
    position: absolute;
    background-image: ('../images/arrow.png');
    width: 11px;
    height: 7px;
    left: 20px;
    top: -1px;
  }
}

/* condition */
.alert .condition {
  position: absolute;
  right: 20px;
  font-size: 12px;
  line-height: 18px;
}

/* badges */
.alert .badge {
  background: $palered;
  border: 1px solid $smudgered;
  color: $smudgered;
  font-size: 12px;
  height: 19px;
  line-height: 19px;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 0 5px 0 2px;
  display: inline-block;
  @include box-shadow(inset 0 1px 0 $palered, 0 1px 0 $basered);
  text-shadow: 0 1px 0 $lightgrey;
}


/* notices */
.notification-neutral,
.notification-notice,
.notification-alert {
  @include border-radius(4px);
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  padding: 8px;
  border: 1px solid #bcb1a3;
  color: #806f60;
  margin: 0 0 9px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255,255,255,.29);
  cursor: pointer;
  position: relative;
  background: #faf6ea;
  @include box-shadow(0 1px 0 rgba(188,177,163,.5),inset 0 1px 0 rgba(255,255,255,.17));
  .icon {
    position: absolute;
    right: 1px;
    top: 3px;
  }
}
.notification-notice {
  border-color: #74a417;
  background: #99ca3c;
  color: #373;
  text-shadow: 0 1px 0 rgba(255,255,255,.29);
  @include box-shadow(0 1px 0 rgba(91,138,0,.5),inset 0 1px 0 rgba(255,255,255,.17));
}
.notification-alert {
  border-color: #7b000c;
  background: #ab0e1d;
  color: white;
  text-shadow: 0 -1px 0 rgba(0,0,0,.29);
  @include box-shadow(0 1px 0 rgba(171,14,29,.5),inset 0 1px 0 rgba(255,255,255,.17));
}

.activities {
  padding: 1px 0 0;
  margin: 20px 0;

  .activity {
    padding: 20px;
    border: 1px solid #bcb1a3;
    margin-top: -1px;
    overflow: auto;

    .comment {
      margin-top: 5px;
      p {
        margin-bottom: 16px;
      }
      p:last-child {
        margin-bottom: inherit !important;
      }
    }
  }
  ul.tags {
    float: left;
    margin: 0 10px 0 0;
  }
  ul.tags li {
    margin: 5px 5px 0 0;
  }
}

/* tabs */
div.container ul.tabs {
  list-style-type: none;
  overflow: auto;
  padding: 0;
  position: relative;
  top: 1px;

  li {
    float: left;
    height: 32px;
    padding: 0 10px;

    span {
      display: block;
      line-height: 32px;
      cursor: pointer;
    }

    &:first-child {
      padding-left: 0;
    }
    &.active span {
      font-weight: bold;
      background-image: url('../images/arrow_up.png');
      background-position: center bottom;
      background-repeat: no-repeat;
    }
  }
}
.tab {
  display: none;
  &.active {
    display: block;
  }
  fieldset.box {
    border: 1px solid #bcb1a3;
  }
  .tab fieldset {
    border-width: 0;
    @include box-shadow();
  }
  .simple_form fieldset {
    .input label {
      width: 165px;
      padding-right: 20px;
      text-align: right;
      font-weight: bold;
    }
    .input textarea {
      height: 62px;
    }
    &.boxComment {
      .input label[for="activity_comment"] {
        display: none;
      }

      #activity_external_settings label.checkbox {
        text-align: left;
        display: block;
        float: left;
        margin: 0;
        width: auto;
        font-weight: normal;
      }

      .input textarea {
        width: 100%;
      }
    }
  }
}
div.container ul.token-input-list {
  border: 1px solid #BAB1A8;
  @include box-shadow(0 1px 1px #D4CCC9 inset);
  line-height: 20px;
  overflow: auto;
  padding: 7px 9px;
  width: 520px;
  list-style-type: none;
  float: left;

  .token-input-token {
    border: 1px solid #BAB1A8;
    position: relative;
    padding: 0 22px 0 5px;
    p {
      float: left;
      line-height: 20px;
      margin: 0;
      font-size: 11px;
    }
    span.token-input-delete-token {
      position: absolute;
      right: 4px;
      top: 3px;
      background-color: #b4a698;
      width: 14px;
      height: 14px;
      line-height: 12px;
      color: white;
      @include border-radius(7px);
      text-align: center;
    }
  }
  #token-input-escalation_user_ids {
    border: 0 none;
    @include box-shadow();
    padding: 0;
    width: auto;
  }
  li {
    float: left;
    line-height: 20px;
    margin: 0 5px 0 0;
  }
}
.token-input-dropdown {
  background-color: #FAF6EA;
  border: 1px solid #BAB1A8;
  line-height: 30px;
  text-indent: 5px;
  p {
    line-height: 20px;
    padding: 5px;
  }
  ul {
    list-style-type: none;
    padding: 0;
    li {
      border-bottom: 1px solid #f2ede0;
      line-height: 20px;
      cursor: pointer;
      padding: 5px;

      &:hover {
        background-color: #f2ede0;
      }
      &:last-child {
        border: 0 none;
      }
    }
  }
}

.escalation {
  background-color: $palered;
  border: 1px solid $beige;
  padding:20px;
  margin: 20px 0;
  position: relative;

  h2 {
    font-size: 16px;
    padding: 7px 0 5px;
    i.icon {
      line-height: 20px;
    }
  }
  .comment {
    p {}
  }
  ul.rsvps {
    list-style-type: none;
    padding: 10px 0 0;
    li {
      i.icon {
        font-size: 21px;
        line-height: 21px;
      }
      a {
        color: black;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    li.rsvp-declined {
      color: #806f60;
      a {
        color: #806f60;
      }
    }
  }
  .actions {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}


.subscription {
  margin: 10px 0;
  font-size: 11px;
  i.icon {
    font-size: 24px;
  }
  strong,
  a {
    font-size: 11px;
  }
}

#escalation fieldset.invitees {
  background: white;
}
