nav.pagination {
  text-align: right;
  margin: 15px 0;
  & > span > a {
    display: inline-block;
    padding: 5px;
    border: #BAB1A8 1px solid;
    &:hover {
      background-color: darken(#fff, 5%);
    }
  }
}