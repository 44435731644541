/* box styles */
@mixin border-radius($radius: 0) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -khtml-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}
@mixin box-shadow($shadow1: 0 0 0 transparent, $shadow2:false, $shadow3:false, $shadow4:false, $shadow5:false) {
  $params: $shadow1;
  @if $shadow2 {
    $params: $shadow1, $shadow2;
  }
  @if $shadow3 != false {
    $params: $shadow1, $shadow2, $shadow3;
  }
  @if $shadow4 != false {
    $params: $shadow1, $shadow2, $shadow3, $shadow4;
  }
  @if $shadow5 != false {
    $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5;
  }

  -webkit-box-shadow: $params;
  -moz-box-shadow: $params;
  -o-box-shadow: $params;
  box-shadow: $params;
}

/* 3D */
/*.perspective (@depth) {
  -webkit-perspective: @depth;
  -moz-perspective: @depth;
  -ms-perspective: @depth;
  -o-perspective: @depth;
  perspective: @depth;
}
.backface-visibility (@attribute) {
  -webkit-backface-visibility: @attribute;
  -moz-backface-visibility: @attribute;
  -ms-backface-visibility: @attribute;
  -o-backface-visibility: @attribute;
  backface-visibility: @attribute;
}*/

/* transitions */
/*.transition (@properties) {
  -webkit-transition: @properties;
  -moz-transition: @properties;
  -ms-transition: @properties;
  -o-transition: @properties;
  transition: @properties;
}
.transition-duration (@duration: 0s) {
  -webkit-transition-duration: @duration;
  -moz-transition-duration: @duration;
  -ms-transition-duration: @duration;
  -o-transition-duration: @duration;
  transition-duration: @duration;
}
.transition-delay (@delay: 0s) {
  -webkit-transition-delay: @delay;
  -moz-transition-delay: @delay;
  -ms-transition-delay: @delay;
  -o-transition-delay: @delay;
  transition-delay: @delay;
}
.transition-property (@properties) {
  -webkit-transition-property: @properties;
  -moz-transition-property: @properties;
  -ms-transition-property: @properties;
  -o-transition-property: @properties;
  transition-property: @properties;
}*/

/* transforms */
/*.transform (@properties) {
  -webkit-transform: @properties;
  -moz-transform: @properties;
  -ms-transform: @properties;
  -o-transform: @properties;
  transform: @properties;
}
.transform-style (@style) {
  -webkit-transform-style: @style;
  -moz-transform-style: @style;
  -ms-transform-style: @style;
  -o-transform-style: @style;
  transform-style: @style;
}*/

/* animations */
/*.animation (@properties) {
  -webkit-animation: @properties;
  -moz-animation: @properties;
  -ms-animation: @properties;
  -o-animation: @properties;
  animation: @properties;
}*/

/* box models */
@mixin box-sizing-border-box ($model: border-box) {
  -webkit-box-sizing: $model;
  -moz-box-sizing: $model;
  box-sizing: $model;
}
@mixin box-sizing ($model) {
  -webkit-box-sizing: $model;
  -moz-box-sizing: $model;
  box-sizing: $model;
  *behavior: none;
}

/* backgrounds */
@mixin gradient ($colorTop, $colorBottom) {
  background: $colorBottom;
  background-image: -moz-linear-gradient(top, $colorTop, $colorBottom);
  background-image: -webkit-gradient(linear, left top, left bottom, from($colorTop), to($colorBottom));
  background-image: -webkit-linear-gradient(top, $colorTop, $colorBottom);
  background-image: -o-linear-gradient(top, $colorTop, $colorBottom);
  background-image: linear-gradient(top, $colorTop, $colorBottom);
  filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$colorTop}', endColorstr='#{$colorBottom}')";
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$colorTop}', endColorstr='#{$colorBottom}')";
  background-image: -ms-linear-gradient(top, $colorTop, $colorBottom);
}
