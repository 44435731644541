/* Generated by Font Squirrel (http://www.fontsquirrel.com) on February 10, 2012 */
@charset 'UTF-8';

@font-face {
  font-family: 'EntypoRegular';
  src: url("../fonts/entypo-webfont.eot");
  src: local('☺'),
       url("../fonts/entypo-webfont.eot#iefix") format('embedded-opentype'),
       url("../fonts/entypo-webfont.woff") format('woff'),
       url("../fonts/entypo-webfont.ttf") format('truetype'),
       url("../fonts/entypo-webfont.svg#EntypoRegular") format('svg');
  font-weight: normal;
  font-style: normal;
}
