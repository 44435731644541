@import "common/all";

.simple_form {
  overflow: auto;
}
fieldset {
  border: 2px solid #BAB1A8;
  @include box-shadow(0 1px 0 #DDD8D2);
  clear: both;
  padding: 20px 20px 0;
  margin: 0 0 15px;
  &.actions {
    border: 0 none;
    @include box-shadow();
    padding: 0;
  }
}
fieldset legend {
  padding: 0 5px;
  color: $darkgrey;
}
.simple_form fieldset.actions input,
.simple_form fieldset.actions .btn {
  float: right;
  margin-left: 10px;
}

.simple_form fieldset .input {
  padding: 0 0 15px 0;
  overflow: auto;
}
.simple_form fieldset .input label {
  width: 105px;
  float: left;
  clear: both;
  line-height: 16px;
  padding: 7px 10px 7px 0;
}
.simple_form fieldset .input label.checkbox {
  padding: 0;
  width: auto;
  float: none;
  clear: none;
  line-height: 18px;
  display: block;
  margin: 0 0 0 110px;
  position: relative;
  top: 5px;
}
.simple_form fieldset .input.check_boxes label {
  width: auto !important;
  float: none;
  display: block;
  text-align: left !important;

  [type=checkbox] {
    margin-right: 5px;
  }
}
.loginForm fieldset .input label.checkbox {
  margin: 0;
}
.simple_form fieldset .input label.checkbox input {
  float: left;
  margin: 3px 10px 0 0;
}
.simple_form fieldset .input label abbr {
  float: right;
  text-decoration: none;
  border: 0 none;
  line-height: 16px;
}
.simple_form fieldset .input input[type=text],
.simple_form fieldset .input input[type=email],
.simple_form fieldset .input input[type=password],
.simple_form fieldset .input input[type=tel],
.simple_form fieldset .input textarea,
.best_in_place input {
  border: 1px solid #bab1a8;
  @include box-shadow(inset 0 1px 1px #d4ccc9);
  padding: 7px 9px;
  line-height: 20px;
  width: 520px;
  height: 36px;
}
.simple_form fieldset .input textarea {
  height: 200px;
}
.best_in_place input {
  width: 100%;
}
.loginForm fieldset .input input[type=email],
.loginForm fieldset .input input[type=password] {
  width: 405px;
}
.simple_form fieldset .input select {
  @include gradient(#f8f6e9,#f0e5d3);
  width: 208px;
  // floating causes problem with the vertical alignment of the hint in the user-profile!
  //float: left;
  height: 30px;
  line-height: 20px;
  padding: 5px 0 5px 8px;
  @include box-shadow(inset 0 1px 0 #fbfbfb);
  border: 1px solid #bab1a8;
}
.simple_form fieldset .input select.date {
  width: 70px;
  margin: 0 10px 0 0;
}
.simple_form fieldset .input .hint {
  padding: 0 0 0 10px;
  color: #bab1a8;
  font-size: 11px;
}

/* alerts */
.simple_form span.error,
.simple_form div.indent {
  display: block;
  clear: both;
  padding-left: 110px;
  line-height: 20px;
}

.simple_form span.error {
  color: $smudgered;
}

/* data table with inline editing */
table.inlineEdit {
  width: 100%;
  border-style: solid;
  border-color: #BAB1A8;
  border-width: 2px 2px 1px;
  @include box-shadow(0 1px 0 #DDD8D2);
  /*border-collapse: collapse;*/
  border-spacing: 0;
  margin: 0 0 15px;
}
table.inlineEdit td {
  border-style: solid;
  border-color: #BAB1A8;
  border-width: 0 0 1px;
  padding: 15px;
  //width: 50%;
  vertical-align: center;
}
table.inlineEdit td.min {
  width: 1%;
}
table.inlineEdit td.edit {
  width: 1%;
  white-space: nowrap;
}

/* archived stuff */
tr.archived { color: #bab1a8; }

/* best in place */
.best_in_place {
  border: 1px dotted white;
  padding: 2px 1px;
  cursor: pointer;
}
.best_in_place:hover {
  border-color: $grey;
  background: $paleyellow;
}
.best_in_place_active,
.best_in_place_active:hover {
  border: 0 none;
  border-color: transparent;
  background: transparent;
}

/* align hints for checkboxes correctly */
.input.boolean.field_with_hint label.checkbox {
  padding-bottom: 15px;
}
.input.boolean.field_with_hint .hint {
  float: left;
  margin-left: 95px;
}

/* adding some custom styling for the timeframe in the user profile, maybe this could be generalized? */
#timeframe_starting_at,
#timeframe_ending_at {
  width: 75px;
}

#new_alert_query fieldset.search > div.input {
  float: left;
  width: 50%;

  // break after second element
  &:nth-child(odd) {
    clear: left;
  }

  > label {
    display: block;
    float: none;
  }
}

// scope around the "big" form, don't affect quick search form
form#new_alert_query {
  #alert_query_keywords,
  #alert_query_service_id,
  #alert_query_condition  {
    width: 80%;
  }
  #alert_query_from, #alert_query_to {
    width: 100px;
  }
}

form#quicksearch {
  .btn-submit {
    float: none;
    margin-left: 0;
  }
}
