@import "common/all";

/* basic button styling */
.btn,
.simple_form fieldset.actions input,
.nav-secondary a.btn {
  @include border-radius();
  text-decoration: none;
  color: $anthrazit;
  font-weight: normal;
  font-size: 12px;
  display: inline-block;
  border: 1px solid $lightgrey;
  height: 28px;
  line-height: 26px;
  padding: 0 5px;
  cursor: pointer;
}
.btn:hover,
.simple_form fieldset.actions input:hover,
.nav-secondary a.btn:hover {
  background: $baseyellow;
  text-decoration: none;
}

/* primary button with nice gradient */
.btn-primary,
.simple_form fieldset.actions input,
.nav-secondary a.btn {
  border-color: $smudgeyellow;
  background: $paleyellow;
  @include box-shadow(inset 0 -15px 0 $baseyellow);
  text-shadow: 0 1px 0 $paleyellow;
}
body .btn-primary:hover,
.simple_form fieldset.actions input:hover,
.nav-secondary a.btn:hover {
  background: $baseyellow;
  @include box-shadow(inset 0 1px 0 $paleyellow);
}

.btnEdit {
  background: #fdf7e9;
  font-size: 11px;
  height: 22px;
  line-height: 20px;
  &:hover {
    background-color: #e8e3d4;
  }
}

/*
a.button {
  border: 1px solid #ecc603;
  @include box-shadow(inset 0 1px 0 #fdf7d3);
  color: #040309;
  text-shadow: 0 1px 0 #ffee9e;
  background-image: url('../../img/bgs/button.png');
  background-repeat: repeat-x;
  background-position: left center;
  background-color: #f5db3e;
  text-decoration: none;
  padding: 0 10px;
  display: inline-block;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
}
a.buttonLight {
  border-color: #c0b0a3;
  background: transparent;
  @include box-shadow();
  text-shadow: 0 0 0 transparent;
}
a.buttonCancel {
  background: transparent;
  border: 0 none;
  height: 30px;
  line-height: 30px;
  @include box-shadow();
  text-shadow: 0 0 0 transparent;
}
*/