@import "common/all";

.menu {
  display: none;
}
header {
  position: relative;
  overflow: auto;
}

header a {
  color: #000;
}
header a:hover {
  text-decoration: none;
}

/* branding */
#branding {
  font-size: 24px;
  line-height: 57px;
  float: left;
  padding: 10px 80px 10px 0;
}
#branding a {
  font-size: 24px;
  line-height: 37px;
  display: block;
}

#branding .logo {
  height: 40px;
  margin: 0 10px 0 0;
  float: left;
}

header ul {
  overflow: hidden;
  list-style-type: none;
}
header li {
  float: left;
}

/* primary/secondary navigation */

/* primary navigation */
.nav-primary {
  float: left;
  height: 57px;
}

.nav-primary a {
  display: block;
  padding: 0 20px;
  line-height: 57px;
  height: 57px;
  font-size: 15px;
  font-weight: bold;
}
.nav-primary a:hover {
  background: $lightyellow;
}
.nav-primary .active {
  background: #fff;
}

/* secondary navigation */
.nav-secondary {
  line-height: 30px;
  padding-top: 13px;
  float: right;
  font-weight: bold;
}
.nav-secondary li {
  padding-left: 5px;
}
.nav-secondary a {
  padding: 0 5px;
  float: left;
  height: 28px;
  line-height: 28px;
  @include border-radius(3px);
  text-decoration: none;
}
.nav-secondary a:hover {
  background: $lightyellow;
}
.nav-secondary .active a {
  background: #fff;
}

/* nav language */
.nav-lang {
  padding: 13px 0 0;
  float: left;
  clear: left;
  line-height: 30px;
}
.nav-lang li {
  padding-right: 5px;
  line-height: 30px;
}
