@import "common/all";

body {
  /* include .gif directly as data, because it's so freaking small */
  background: #fff url('../images/bg_body.gif') repeat-x;
  min-width: 320px;
}

.container {
  padding: 0 40px;
  margin: 0 auto;
  clear: both;
}
div.container {
  padding-top: 20px;
}

/* basic typography */
p {
  color: #000;
  line-height: 16px;
  font-size: 12px;
  margin-bottom: 5px;
}

footer.container {
  margin: 80px auto 20px;
}
footer * {
  color: $lightgrey;
}
footer a {
  color: $grey;
}

.hidden {
  display: none;
}
