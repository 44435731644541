@import "common/all";

* {
  margin: 0;
  padding: 0;
  @include box-sizing-border-box();
  font-family: Verdana, Helvetica, sans-serif;
  font-size: 12px;
  outline: none;
}
label {
  cursor: pointer;
}
img {
  border: 0 none;
}
