@import "common/all";

div.container ul.tags {
  list-style-type: none;
  overflow: auto;
  padding: 0;
  margin: 0;

  i.icon {
    font-size: 32px;
    line-height: 19px;
  }

  li {
    line-height: 19px;
    height: 21px;
    border: 1px solid black;
    @include border-radius(4px);
    float: left;
    margin: 0 5px 5px 0;
    padding: 0 8px 0 5px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 11px;
    position: relative;
    z-index: 1000;

    /* tags list */
    &.transition {
      border: 0;
      font-size: 1.2em;
      padding: 0;
    }
    &.tag-disrupted {
      background-color: #ffc411;
      border-color: rgb(138,104,1);
      color: #7e3000;
      text-shadow: 0 1px 0 #fdc39d;
      @include box-shadow(0 1px 0 rgba(138,104,1,0.5), 0 1px 0 #FEBA93 inset);

      background-color: #ffac97;
      border-color: rgb(221,57,35);
      color: #b71401;
      text-shadow: 0 1px 0 #ffb6a7;
      @include box-shadow(0 1px 0 rgba(221,57,35,0.5), 0 1px 0 #ffb6a7 inset);
  }
    &.tag-bouncing {
      background-color: #ffb07d;
      border-color: rgb(169,84,29);
      color: #7e3000;
      text-shadow: 0 1px 0 #fdc39d;
      @include box-shadow(0 1px 0 rgba(169,84,29,0.5), 0 1px 0 #FEBA93 inset);
    }
    &.tag-maintenance {
      background-color: #ffc411;
      border-color: rgb(138,104,1);
      color: #7e3000;
      text-shadow: 0 1px 0 #fdc39d;
      @include box-shadow(0 1px 0 rgba(138,104,1,0.5), 0 1px 0 #FEBA93 inset);
    }
    &.tag-informative {
      background-color: #c2c2c2;
      border-color: rgb(103,103,103);
      color: #424242;
      text-shadow: 0 1px 0 #cacaca;
      @include box-shadow(0 1px 0 rgba(103,103,103,0.5), 0 1px 0 #cacaca inset);
    }
    &.tag-resolved {
      background-color: #a0e36e;
      border-color: rgb(69,136,19);
      color: #2c6401;
      text-shadow: 0 1px 0 #a8eb76;
      @include box-shadow(0 1px 0 rgba(69,136,19,0.5), 0 1px 0 #a8eb76 inset);
    }
    &.tag-escalated {
      background-color: #ab0e1d;
      border-color: #7b000c;
      color: #fff;
      text-shadow: 0 -1px 0 rgba(0,0,0,0.29);
      @include box-shadow(0 1px 0 rgba(221,57,35,0.5), 0 1px 0 #b93743 inset);

      /*
      background-color: #ffac97;
      border-color: rgb(221,57,35);
      color: #b71401;
      text-shadow: 0 1px 0 #ffb6a7;
      @include box-shadow(0 1px 0 rgba(221,57,35,0.5), 0 1px 0 #ffb6a7 inset);
      */
    }
  }
}
