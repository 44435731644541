@import "common/all";

/* use entypo as icon font */
i.icon {
  font-family: EntypoRegular, sans-serif;
  font-size: 34px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-transform: none;
  line-height: inherit;
  padding: 0 4px 0 0;
  float: left;
  height: 18px;
  overflow: visible;
  position: relative;
  top: -4px;
  line-height: 18px;
}
.btn i.icon {
  top: 0;
  font-size: 30px;
}

.btnEdit i.icon {
  font-size: 24px;
  line-height: 14px;
}


/*
  c => warning
  Q => flash
  W => checko
  X => cross
  @ => cog
  & => edit
*/