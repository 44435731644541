@import "common/all";

.archives {
  
  .archive > h2 {
    font-size: 12px;
    line-height: 1;
    border: 2px solid $beige;
    color: $beige;
    padding: 20px 17px;
    cursor: pointer;
    margin-bottom: 15px;
  }

  .archived-alerts > h2 {
    margin-bottom: 0px !important;
    border-bottom: 0 !important;
  }

  .archive > h2 em {
    font-style: normal;
    font-weight: normal;
  }

  .archive > h2.active {
    color: #000;
  }

  .archived-alerts .alert {
    border-width: 2px 2px 0;
    &:last-child {
      margin-bottom: 40px;
      border-width: 2px;
    }

    /* hide edit button in list */
    .header .btnEdit {
      display: none;
    }
  }
}
