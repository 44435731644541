@import "common/all";

.container {
  max-width: 1200px;
}

@media only screen and (max-width : 640px) {
  header nav {
    display: none;
    position: absolute;
    left: 0;
    top: 57px;
    width: 100%;
    background-color: #fef7d6;
    z-index: 10000;
    @include box-shadow(0 10px 15px rgba(0,0,0,0.5));
  }
  header.showNav nav {
    display: block;
  }
  header {
    background-color: #fef7d6;
    background-image: url('../images/bg_body.gif');
    background-repeat: repeat-x;
    overflow: visible;
    &.container {
      padding: 0;
    }
    ul {
      overflow: auto;
      padding: 0;
    }
    li {
      float: none;
      border-bottom: 1px solid $paleyellow;
    }
  }
  #branding {
    float: none;
    padding: 10px 20px;
  }
  .simple_form fieldset .input input[type=text],
  .simple_form fieldset .input input[type=email],
  .simple_form fieldset .input input[type=password],
  .simple_form fieldset .input input[type=tel],
  .simple_form fieldset .input textarea,
  .best_in_place input,
  .simple_form fieldset .input select{
    width: 100%;
  }

  .simple_form fieldset .input select.date {
    width: auto;
  }

  .simple_form fieldset .input label.date {
    width: 100%;
  }

  .loginForm fieldset .input input[type=email],
  .loginForm fieldset .input input[type=password] {
    width: 100%;
  }
  .loginForm {
    width: auto;
  }
  .loginForm fieldset .input input[type="email"],
  .loginForm fieldset .input input[type="password"] {
    width: 100%;
  }

  .simple_form fieldset .input.date select.date {
    //margin-bottom: 15px;
  }

  .simple_form fieldset .input label.checkbox {
    margin-left: 0;
  }

  .input.boolean.field_with_hint .hint {
    margin-left: 0;
  }

  /* primary navigation */
  .nav-primary {
    float: none;
    height: auto;
  }
  .nav-primary a {
    padding: 5px 20px;
    line-height: 30px;
    height: auto;
    font-size: 15px;
    font-weight: normal;
  }
  .nav-primary a:hover {
    background: #fef7d6;
  }
  .nav-primary .active a {
    font-weight: bold;
  }
  .nav-primary .active a:hover {
    background: #fff;
  }

  /* secondary navigation */
  .nav-secondary {
    padding-top: 0;
    float: none;
    font-weight: normal;
  }
  .nav-secondary li {
    padding: 0;
  }
  .nav-secondary a {
    padding: 5px 20px;
    float: none;
    line-height: 30px;
    height: auto;
    font-size: 15px;
    @include border-radius();
    text-decoration: none;
    display: block;
    font-weight: normal;
  }
  .nav-secondary a:hover {
    background: #fef7d6;
  }
  .nav-secondary .active a {
    font-weight: bold;
  }
  .nav-secondary .active a {
    background: #fff;
  }
  .nav-secondary a.btn,
  .nav-secondary a.btn:hover {
    margin: 0;
    border: 0 none;
    width: 100%;
    padding: 0 20px;
    line-height: 40px;
    height: 40px;
    font-size: 15px;
    background: #fef7d6;
    @include box-shadow();
  }

  .container {
    padding: 0 20px;
    margin: 0 auto;
    clear: both;
  }
  div.container {
    padding-top: 20px;
  }

  /* nav language */
  .nav-lang {
    padding: 13px 0 0;
    float: left;
    clear: left;
    line-height: 30px;
  }
  .nav-lang li {
    padding-right: 5px;
    line-height: 30px;
  }

  .alert .header h3,
  .alert .header ul.tags {
    float: left;
  }

  .alert .header h3,
  .alert .header h3 a {
    font-size: 14px;
  }

  .alert .header .right {
    float: none;
    position: relative;
    text-align: left;
    top: 0px;
    left: 0px;
    width: 100%;
  }
  .alert .header .right .number {
    font-size: 12px;
    float: right;
  }

  .alert .header {
    padding: 5px;
  }

  .alert .body dt, .alert .body dd {
    float: none;
    margin-left: 0;
    width: auto;
    text-align: left;
  }

  .stamp {
    time.default { display: none; }
    time.numeric { display: inline; }
  }



  .tab {
    .simple_form fieldset {
      .input label {
        width: auto;
        float: none;
        padding: 0;
        text-align: left;
        display: block;
        line-height: 20px;
      }
      .input textarea {
        height: 62px;
        width: 100%;
      }
      &.boxComment {
        .input label {
          display: none;
        }
        .input textarea {
          width: 100%;
        }

        #activity_external_teams label.checkbox {
          float: none;
        }
      }
    }
  }

  div.container ul.token-input-list {
    width: 100%;
  }
  .subscription a {
    white-space: nowrap;
  }
  .escalation .actions {
    position: static;
    padding: 10px 0 0;
  }
  table.inlineEdit {
    margin: 0;
  }
  table.inlineEdit td {
    padding: 10px;
  }

  div.container {
    overflow: auto;
  }
  .scrollWrapper {
    overflow: auto;
    margin: 0 0 15px;
  }
  .simple_form fieldset .input label abbr {
    float: none;
  }
  .simple_form fieldset .input select {
    width: 100%;
  }
  .simple_form fieldset .input label {
    padding: 0 0 4px;
    line-height: 16px;
  }
  .simple_form span.error,
  .simple_form div.indent {
    padding: 0;
  }
  .simple_form fieldset .input label.check_boxes {
    float: none;
  }

  .menu {
    position: absolute;
    right: 20px;
    top: 10px;
    @include border-radius();
    text-decoration: none;
    color: $anthrazit;
    font-weight: normal;
    font-size: 12px;
    display: inline-block;
    border: 1px solid #DFC300;
    height: 34px;
    line-height: 32px;
    padding: 0 20px;
    cursor: pointer;
    background-color: #F3E5A8;
    @include box-shadow(0 -15px 0 #ECD46F inset);
    text-shadow: 0 1px 0 #F3E5A8;
  }

  .activities {
    ul.tags {
      float: none;
    }
  }

  form#new_alert_query {
    fieldset.search > div.input {
      width: 100%;
      float: none;
      display: block;
    }

    #alert_query_keywords,
    #alert_query_service_id,
    #alert_query_condition {
      width: 100%;
    }
  }

  // hide quicksearch input field since it's too long
  form#quicksearch {
    #alert_query_keywords {
      display: none;
    }
  }

  // add margin-bottom to give some space when breaking on mobile device
  #new_activity .actions .btn,
  #new_activity .actions .btn-primary {
    margin-bottom: 10px;
  }
}
